export const MONTH_NAMES = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]

export const DAY_NAMES = [
  'Minggu',
  'Senin',
  'Selasa',
  'Rabu',
  'Kamis',
  'Jumat',
  'Sabtu',
]

export const times = [
  '07.00',
  '07.30',
  '08.00',
  '08.30',
  '09.00',
  '09.30',
  '10.00',
  '10.30',
  '11.00',
  '11.30',
  '12.00',
  '12.30',
  '13.00',
  '13.30',
  '14.00',
  '14.30',
  '15.00',
  '15.30',
  '16.00',
  '16.30',
  '17.00',
  '17.30',
  '18.00',
  '18.30',
  '19.00',
]

export const setIdTimePicker = (time) => {
  if (time == '07.30' || time == '07.00' || time == '08.00') return '07.00'
  if (time == '18.30' || time == '19.00' || time == '18.00') return '17.00'
  switch (time) {
    case '08.30':
      return '07.30'
    case '09.00':
      return '08.00'
    case '09.30':
      return '08.30'
    case '10.00':
      return '09.00'
    case '10.30':
      return '09.30'
    case '11.00':
      return '10.00'
    case '11.30':
      return '10.30'
    case '12.00':
      return '11.00'
    case '12.30':
      return '11.30'
    case '13.00':
      return '12.00'
    case '13.30':
      return '12.30'
    case '14.00':
      return '13.00'
    case '14.30':
      return '13.30'
    case '15.00':
      return '14.00'
    case '15.30':
      return '14.30'
    case '16.00':
      return '15.00'
    case '16.30':
      return '15.30'
    case '17.00':
      return '16.00'
    case '17.30':
      return '16.30'
    default:
      break
  }
}

export const dateGMT = (defaultDate) => {
  let date = new Date(defaultDate).toUTCString()
  return new Date(date).setHours(new Date(date).getHours() + 7)
}
