/* eslint-disable no-unused-vars */
import ModalMaps from 'components/modal/ModalMaps'
import { useFetchOrderStatistic } from 'modules/AboutUs/hook'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React from 'react'
import CountUp from 'react-countup'

import AboutUsLayout from './fragments/Layout'

function AboutUs() {
  const {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
    redirectToExploreIfAddressFilled,
  } = useGoogleMapHook()

  const dataHook = useFetchOrderStatistic()

  return (
    <AboutUsLayout>
      <div className="border-b py-10 md:py-20 pb-16 md:pb-20">
        <div className="pb-10 md:pb-14">
          <div className="w-full flex flex-col justify-center items-center">
            {/* TITLE */}
            <div className="text-center">
              <h2 className="text-lg md:text-2xl text-black">Tentang</h2>
              <h2 className="text-[26px] md:text-[34px] text-primary font-semibold">
                kendiXpress
              </h2>
            </div>
            {/* END TITLE */}
            {/* DESCRIPTION */}
            <div className="text-center pt-6 md:mx-72">
              <div>
                Kendixpress adalah marketplace pesan antar makanan untuk kamu
                yang punya event di rumah maupun di kantor. Selain untuk event,
                kami juga menyediakan catering harian dengan menu yang berbeda
                setiap harinya. Ratusan variasi menu mulai dari menu Catering
                sampai Resto dengan harga terjangkau, bisa kamu mulai{' '}
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => redirectToExploreIfAddressFilled()}
                >
                  pesan sekarang
                </span>
                .
              </div>
            </div>
            {/* END DESCRIPTION */}
            {/* COUNTER */}
            <div className="mt-8">
              <div className="flex flex-wrap justify-between items-center">
                <div className="border rounded-2xl p-6 text-center w-full md:w-[275px] md:mr-3 my-2 md:my-0">
                  <h3 className="text-primary text-lg">Total Transaksi</h3>
                  <h2 className="text-primary text-2xl font-semibold">
                    <CountUp
                      end={dataHook.data && dataHook.data.total_transaction}
                      duration="1"
                    />{' '}
                    Order
                  </h2>
                </div>
                <div className="border rounded-2xl p-6 text-center w-full md:w-[275px] md:ml-3 my-2 md:my-0">
                  <h3 className="text-primary text-lg">Produksi Harian</h3>
                  <h2 className="text-primary text-2xl font-semibold">
                    <CountUp
                      end={dataHook.data && dataHook.data.daily_max}
                      duration="1"
                    />{' '}
                    Porsi
                  </h2>
                </div>
              </div>
            </div>
            {/* END COUNTER */}
          </div>
        </div>
        {/* ADDRESS */}
        <div className="pt-10 md:pt-14 md:mx-72 border-t">
          <div className="w-full flex flex-col justify-center items-center">
            <h2 className="text-black text-2xl">Alamat Kantor</h2>
            <div className="mt-6 text-center">
              <div className="text-sm text-primary">Kantor Pusat</div>
              <div className="text-sm text-black">
                Treasury Tower Lt. 3 Unit H District 8 SCBD Lot. 28,
                <br />
                Jl. Jend. Sudirman Kav. 52-53, Kecamatan Kebayoran Baru,
                <br />
                Jakarta Selatan 12190, Indonesia
              </div>
            </div>
            <div className="mt-6 text-center">
              <div className="text-sm text-primary">Kantor Cabang</div>
              <div className="text-sm text-black">
                Jl. Anggur 1 No. 19 RT:019/RW:003, Cipete Selatan, Cilandak,
                <br />
                Jakarta Selatan 12410, Indonesia
              </div>
            </div>
          </div>
        </div>
        {/* END ADDRESS */}
      </div>
      {/* MODAL SET LOCATION */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
      />
    </AboutUsLayout>
  )
}

export default AboutUs
