import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from 'components/button/LoadingButton'
import Form from 'components/forms/Form'
import PlainInput from 'components/forms/PlainInput'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTimer } from 'react-timer-hook'
import { formatTime, isNumber } from 'utils/helpers'
import * as yup from 'yup'

function OtpInputContent({
  phoneNumber,
  handleSubmitOtp,
  retryOtp,
  errorMessage,
  setErrorMessage,
  anotherOptionOtp,
  loadingOtp,
}) {
  const schema = yup.object().shape({
    firstDigit: yup.string().required(),
    secondDigit: yup.string().required(),
    thirdDigit: yup.string().required(),
    fourthDigit: yup.string().required(),
    fifthDigit: yup.string().required(),
    sixthDigit: yup.string().required(),
  })

  const [counterStep, setCounterStep] = useState(1)

  const time = new Date()
  time.setSeconds(time.getSeconds() + 60)

  const { seconds, minutes, isRunning, start, restart } = useTimer({
    expiryTimestamp: time,
    autoStart: false,
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      firstDigit: '',
      secondDigit: '',
      thirdDigit: '',
      fourthDigit: '',
      fifthDigit: '',
      sixthDigit: '',
    },
  })

  const onDeleteValue = (e, currentValue, previousValue) => {
    if (e.keyCode == 8) {
      e.target.value = ''
      setValue(currentValue, '')
      if (!e.target.value) {
        e.preventDefault()
        e.target.previousElementSibling.value = ''
        setValue(previousValue, '')
        e.target.previousElementSibling.focus()
      }
    }
  }

  const onInputValue = (e) => {
    if (e.target.value) {
      e.target.nextElementSibling.focus()
    }
  }

  const handleSubmitForm = (data) => {
    handleSubmitOtp(data)
  }

  const isOtpError = () => {
    if (
      errors.firstDigit ||
      errors.secondDigit ||
      errors.thirdDigit ||
      errors.fourthDigit ||
      errors.fifthDigit ||
      errors.sixthDigit
    )
      return true
  }

  useEffect(() => {
    start(time)
  }, [])

  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">
        Masukkan Kode Verifikasi
      </h4>
      <span className="text-sm text-black leading-6">
        Masukkan kode verifikasi yang kami
        <br />
        kirim melalui SMS ke nomor HP mu
        <br />
        <strong>{phoneNumber}</strong>
      </span>

      <Form autoComplete="off">
        <div className="mt-12 flex">
          <Controller
            name="firstDigit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="number"
                name="firstDigit"
                classInput={`w-1/6 mx-2 text-center !text-xl ${
                  isOtpError() || errorMessage
                    ? '!border-b-primary text-primary'
                    : ''
                }`}
                maxLength="1"
                onKeyDown={(e) => {
                  isNumber(e)
                }}
                onInput={(e) => onInputValue(e)}
              />
            )}
          />
          <Controller
            name="secondDigit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="number"
                name="secondDigit"
                classInput={`w-1/6 mx-2 text-center !text-xl ${
                  isOtpError() || errorMessage
                    ? '!border-b-primary !text-primary'
                    : ''
                }`}
                maxLength="1"
                onKeyDown={(e) => {
                  isNumber(e)
                  onDeleteValue(e, 'secondDigit', 'firstDigit')
                }}
                onInput={(e) => onInputValue(e)}
              />
            )}
          />
          <Controller
            name="thirdDigit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="number"
                name="thirdDigit"
                classInput={`w-1/6 mx-2 text-center !text-xl ${
                  isOtpError() || errorMessage
                    ? '!border-b-primary text-primary'
                    : ''
                }`}
                maxLength="1"
                onKeyDown={(e) => {
                  isNumber(e)
                  onDeleteValue(e, 'thirdDigit', 'secondDigit')
                }}
                onInput={(e) => onInputValue(e)}
              />
            )}
          />
          <Controller
            name="fourthDigit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="number"
                name="fourthDigit"
                classInput={`w-1/6 mx-2 text-center !text-xl ${
                  isOtpError() || errorMessage
                    ? '!border-b-primary text-primary'
                    : ''
                }`}
                maxLength="1"
                onKeyDown={(e) => {
                  isNumber(e)
                  onDeleteValue(e, 'fourthDigit', 'thirdDigit')
                }}
                onInput={(e) => onInputValue(e)}
              />
            )}
          />
          <Controller
            name="fifthDigit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="number"
                name="fifthDigit"
                classInput={`w-1/6 mx-2 text-center !text-xl ${
                  isOtpError() || errorMessage
                    ? '!border-b-primary text-primary'
                    : ''
                }`}
                maxLength="1"
                onKeyDown={(e) => {
                  isNumber(e)
                  onDeleteValue(e, 'fifthDigit', 'fourthDigit')
                }}
                onInput={(e) => onInputValue(e)}
              />
            )}
          />
          <Controller
            name="sixthDigit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <PlainInput
                {...field}
                type="number"
                name="sixthDigit"
                classInput={`w-1/6 mx-2 text-center !text-xl ${
                  isOtpError() || errorMessage
                    ? '!border-b-primary text-primary'
                    : ''
                }`}
                maxLength="1"
                onKeyDown={(e) => {
                  isNumber(e)
                  onDeleteValue(e, 'sixthDigit', 'fifthDigit')
                }}
              />
            )}
          />
        </div>
        <div className="mt-11 mb-9 text-center">
          {isRunning && (
            <span className="text-xs">
              {formatTime(minutes)}:{formatTime(seconds)}
            </span>
          )}
          {!isRunning && counterStep == 1 && (
            <>
              <span className="text-xs">Tidak menerima kode OTP?</span>
              <div
                className="mt-2 text-xs text-primary cursor-pointer"
                onClick={() => {
                  const timeRestart = new Date()
                  timeRestart.setSeconds(timeRestart.getSeconds() + 60)
                  restart(timeRestart)
                  setCounterStep((prevValue) => prevValue + 1)
                  retryOtp && retryOtp(phoneNumber)
                }}
              >
                Kirim Ulang
              </div>
            </>
          )}
          {!isRunning && counterStep == 2 && (
            <>
              <span className="text-xs">Tidak menerima kode OTP?</span>
              <div
                className="mt-2 text-xs text-primary cursor-pointer"
                onClick={() =>
                  anotherOptionOtp && anotherOptionOtp('alternative-otp')
                }
              >
                Coba metode verifikasi lain
              </div>
            </>
          )}
        </div>
        <LoadingButton
          className="!px-2 md:!px-3 !py-3 font-semibold !w-full"
          isRounded={true}
          isLoading={loadingOtp}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit(handleSubmitForm)(e)
            setErrorMessage('')
          }}
        >
          Masuk
        </LoadingButton>
      </Form>
      <div
        className={`${
          errorMessage || isOtpError() ? 'mt-4' : 'mt-7'
        } text-center`}
      >
        {errorMessage && (
          <div className="text-[10px] leading-3 mb-1 text-primary">
            {errorMessage === 'Server error, harap hubungi administrator'
              ? errorMessage
              : 'Kode verifikasi salah, coba ulangi'}
          </div>
        )}
        {isOtpError() && (
          <div className="text-[10px] leading-3 mb-1 text-primary">
            Kode verifikasi wajib diisi
          </div>
        )}
        <div className="text-[10px] leading-3 text-black">
          Butuh Bantuan?{' '}
          <a
            href="https://wa.me/6281285949895?text=Saya%20mengalami%20kesulitan%20ketika%20mendaftar%20di%20website"
            target={'_blank'}
            rel="noreferrer"
            className="text-primary"
          >
            Klik disini
          </a>
        </div>
      </div>
    </div>
  )
}

export default OtpInputContent
