/* eslint-disable no-unused-vars */
import ModalMaps from 'components/modal/ModalMaps'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import React, { useState } from 'react'

function Order({ layout = 'home' }) {
  const {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    isPartnerExist,
    setIsPartnerExist,
    handleSubmitForm,
    saveLocationMutation,
    redirectToExploreIfAddressFilled,
  } = useGoogleMapHook()

  return (
    <section className="bg-primary">
      <div className="container">
        <div className="py-11 hidden md:flex md:justify-center md:items-center">
          {layout == 'home' && (
            <h2 className="text-white text-2xl">
              Percayakan pada Kami
              <span className="font-merienda font-semibold ml-3 text-2xl">
                Kebutuhan Makan setiap Eventmu
              </span>
            </h2>
          )}
          {layout == 'info' && (
            <div>
              <h3 className="text-white text-xl font-semibold">
                Masih memiliki pertanyaan? Ayo jangan ragu untuk bertanya
              </h3>
              <h2 className="text-white text-2xl mt-1">
                Tim kendiXpress siap membantumu
              </h2>
            </div>
          )}
          <div className="ml-auto">
            {layout == 'home' && (
              <button
                className="px-14 py-2 rounded-full bg-white border-2 border-white text-primary font-bold w-auto text-sm"
                onClick={() => redirectToExploreIfAddressFilled()}
              >
                Pesan Sekarang
              </button>
            )}
            {layout == 'info' && (
              <a
                href="https://wa.me/6281285949895?text=Saya%20ingin%20bertanya%20tentang%20pesan%20Catering%20di%20kendiXpress"
                target="_blank"
                className="px-20 py-2 rounded-full bg-white border-2 border-white text-primary font-bold w-auto text-sm"
                rel="noreferrer"
              >
                Kontak Kami
              </a>
            )}
          </div>
        </div>
        <div className="py-10 flex flex-col md:hidden justify-center items-center">
          {layout == 'home' && (
            <>
              <h2 className="text-white text-xl mb-3">Percayakan pada Kami</h2>
              <h2 className="font-merienda font-semibold text-white text-center text-3xl mb-8">
                Kebutuhan Makan <br /> setiap Eventmu
              </h2>
            </>
          )}
          {layout == 'info' && (
            <>
              <h2 className="text-white text-base mb-4 text-center font-semibold">
                Masih memiliki pertanyaan? <br /> Ayo jangan ragu untuk bertanya
              </h2>
              <h2 className="font-merienda font-normal text-white text-center text-sm mb-8">
                Tim kendiXpress siap membantumu
              </h2>
            </>
          )}
          {layout == 'info' && (
            <a
              href="https://wa.me/6281285949895?text=Saya%20ingin%20bertanya%20tentang%20pesan%20Catering%20di%20kendiXpress"
              target="_blank"
              className="px-16 py-2 rounded-full bg-white border-2 border-white text-primary font-bold w-auto"
              rel="noreferrer"
            >
              Kontak Kami
            </a>
          )}
          {layout == 'home' && (
            <button
              className="px-16 py-2 rounded-full bg-white border-2 border-white text-primary font-bold w-auto"
              onClick={() => redirectToExploreIfAddressFilled()}
            >
              Pesan Sekarang
            </button>
          )}
        </div>
      </div>
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        place={place}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
      />
    </section>
  )
}

export default Order
