import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'styles/components/carousel.css'
import 'styles/components/googlemaps.css'
import 'tippy.js/dist/tippy.css'

import ProtectedRoute from 'components/ProtectedRoute'
import RedirectCartRoute from 'components/RedirectCartRoute'
import usePageTracking from 'modules/custom/usePageTracking'
import DetailMitra from 'pages/DetailMitra'
import CartMobile from 'pages/DetailMitra/CartMobile'
import Explore from 'pages/Explore/Index'
import PaymentConfirm from 'pages/PaymentConfirm'
import PaymentInfo from 'pages/PaymentInfo'
import Address from 'pages/User/Address'
import OrderDetail from 'pages/User/OrderDetail'
import OrderList from 'pages/User/OrderList'
import Profile from 'pages/User/Profile'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom'

import AboutUs from './pages/AboutUs/Index'
import Faq from './pages/FAQ/Index'
import Home from './pages/Home/Index'
import PrivacyPolicy from './pages/PrivacyPolicy/Index'
import TnC from './pages/TnC/Index'

function App() {
  usePageTracking()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        keepPreviousData: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="/explore" element={<Explore />} />
        <Route
          path="/user/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/address"
          element={
            <ProtectedRoute>
              <Address />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order"
          element={
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
        />
        <Route path="/detail-mitra/:id" element={<DetailMitra />} />
        <Route path="/cart/:id" element={<CartMobile />} />
        <Route
          path="/payment-info"
          element={
            <RedirectCartRoute>
              <PaymentInfo />
            </RedirectCartRoute>
          }
        />
        <Route
          path="/payment-confirmation"
          element={
            <RedirectCartRoute>
              <PaymentConfirm />
            </RedirectCartRoute>
          }
        />
      </Routes>
    </QueryClientProvider>
  )
}

export default App
