import SectionLayout from 'components/layouts/SectionLayout'
import BcaLogo from 'images/company/bca.png'
import BniLogo from 'images/company/bni.png'
import DhlLogo from 'images/company/dhl.png'
import MindIdLogo from 'images/company/mind-id.png'
import ProfileBca from 'images/testimonial/testimoni_bca.jpg'
import ProfileBni from 'images/testimonial/testimoni_bni.jpg'
import ProfileDhl from 'images/testimonial/testimoni_dhlexpress.jpg'
import ProfileMindId from 'images/testimonial/testimoni_mindid.jpg'
import TestimonialItem from 'pages/Home/components/fragments/TestimonialItem'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'

function Testimonial() {
  // UPDATE STYLING CAROUSEL AT carousel.css

  return (
    <SectionLayout className="mt-20 mb-5 md:mt-24 md:mb-24" id="testimonial">
      {/* TITLE SECTION */}
      <div className="w-full mx-0 md:mx-24 mb-0 md:mb-10">
        <h3 className="text-black text-center md:text-left text-sm md:text-xl mb-3">
          Tentang Kami
        </h3>
        <h1 className="text-primary font-merienda text-center md:text-left text-xl md:text-4xl font-semibold tracking-[-0.04em]">
          Kata Mereka Yang Tercinta
        </h1>
      </div>
      {/* END TITLE SECTION */}

      {/* CAROUSEL TESTIMONIAL */}
      <Carousel
        className="testi-carousel"
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={true}
        infiniteLoop={true}
        autoPlay={true}
        stopOnHover={true}
        interval={5000}
        transitionTime={500}
        swipeable={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        renderItem={(item, props) => <item.type {...item.props} {...props} />}
        renderThumbs={(children) =>
          // THUMBS IMAGE LOGIC
          children.map((item, index) => {
            return (
              <div key={item}>
                <img
                  src={
                    index == 0
                      ? MindIdLogo
                      : index == 1
                      ? DhlLogo
                      : index == 2
                      ? BniLogo
                      : index == 3
                      ? BcaLogo
                      : ''
                  }
                />
              </div>
            )
          })
        }
      >
        <TestimonialItem
          imgPerson={ProfileMindId}
          descTestimonial={
            <div className="text-sm md:text-lg text-center md:text-left order-0">
              Jangan pernah pusing dan bingung memilih menu makanan, karena ada{' '}
              <br />
              kendiXpress solusinnya. Makan siang Karyawan atau acara apapun
              bisa, <br />
              menunya banyak variasi, rasanya eehhhhm mantul, servicenya cepat
              dan Ok <br />
              deh pokoknya,
            </div>
          }
          endTestimonial={
            <p className="text-sm md:text-lg mt-6 text-center md:text-left order-1">
              Terima kasih kendiXpress.
            </p>
          }
          namePerson={`PT Mind ID`}
        />
        <TestimonialItem
          imgPerson={ProfileDhl}
          descTestimonial={
            <div className="text-sm md:text-lg text-center md:text-left order-0">
              Menurut saya KendiXpress adalah salah satu vendor catering yang
              saya
              <br />
              rekomendasikan, Mulai dari rasa, harga dan pelayanan KendiXpress
              <br />
              juaranya. Makanya cukup lama kami menjadi rekanan, Karena apa yang
              kami butuhkan hampir semua bisa di penuhi.
            </div>
          }
          endTestimonial={
            <p className="text-sm md:text-lg mt-6 text-center md:text-left order-1">
              Terima kasih KendiXpress semoga semakin maju dan sukses usahanya.
            </p>
          }
          namePerson={`DHL Express`}
        />
        <TestimonialItem
          imgPerson={ProfileBni}
          descTestimonial={
            <div className="text-sm md:text-lg text-center md:text-left order-0">
              Suka bingung mau makan siang apa sejak COVID karena pastinya gak
              mau
              <br />
              makan sembarangan. Untung ada kendiXpress. Menunya bervariasi,
              rasanya
              <br />
              maknyus, harga pas, pelayannnya cepat juga ramah.
            </div>
          }
          endTestimonial={
            <p className="text-sm md:text-lg mt-6 text-center md:text-left order-1">
              Thank you kendiXpress!
            </p>
          }
          namePerson={`BNI`}
        />
        <TestimonialItem
          imgPerson={ProfileBca}
          descTestimonial={
            <div className="text-sm md:text-lg text-center md:text-left order-0">
              Kami sangat terbantu oleh katering kendiXpress terutama di masa
              wabah
              <br />
              COVID ini. Respon semua teman-teman bagus, katanya makanannya
              enak!
              <br />
              Pokoknya recommend banget deh!
            </div>
          }
          endTestimonial={
            <p className="text-sm md:text-lg mt-6 text-center md:text-left order-1">
              Makasih & sukses terus ya buat kendiXpress.
            </p>
          }
          namePerson={`BCA`}
        />
      </Carousel>
      {/* END CAROUSEL TESTIMONIAL */}
    </SectionLayout>
  )
}

export default Testimonial
