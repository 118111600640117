/* eslint-disable no-unused-vars */
import Tippy from '@tippyjs/react'
import SectionTitle from 'components/layouts/fragments/SectionTitle'
import SectionLayout from 'components/layouts/SectionLayout'
import ModalMaps from 'components/modal/ModalMaps'
import { useGoogleMapHook } from 'modules/custom/useGoogleMapHook'
import ServiceItem from 'pages/Home/components/fragments/ServiceItem'
import ServiceWrapper from 'pages/Home/components/fragments/ServiceWrapper'
import React, { useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

function OurServices() {
  const [location] = useState(() => {
    const saved = localStorage.getItem('dataAddress')
    const initialValue = JSON.parse(saved)
    return initialValue || ''
  })
  const navigate = useNavigate()
  const {
    showModal,
    setShowModal,
    latLong,
    setLatLong,
    place,
    setPlace,
    setURL,
    setRegion,
    isPartnerExist,
    setIsPartnerExist,
    searchValue,
    setSearchValue,
    setPartnerTypeId,
    menuKeyword,
    setMenuKeyword,
    menuPrice,
    setMenuPrice,
    handleSubmitForm,
    saveLocationMutation,
    redirectToExploreIfAddressFilled,
  } = useGoogleMapHook()

  const handleClick = (query) => {
    if (query === 2) {
      setPartnerTypeId(query)
      setSearchValue('')
      sessionStorage.setItem('searchValue', JSON.stringify(''))
      sessionStorage.setItem('partnerType', JSON.stringify(query))
    } else {
      setSearchValue(query)
      sessionStorage.setItem('searchValue', JSON.stringify(query))
      sessionStorage.setItem('partnerType', JSON.stringify(''))
    }
    if (location == '') {
      setShowModal(true)
    } else {
      navigate(`/explore`)
    }
  }

  return (
    <SectionLayout className={`mt-[32px] md:mt-[73px]`} id="our-service">
      <SectionTitle title={`Layanan Kami`} subtitle={`Untuk Setiap Momen`} />
      <ServiceWrapper>
        <div className="flex flex-nowrap justify-center items-center">
          {/* <ServiceItem
            onClick={() => handleClick('ricebowl')}
            classWrapper={`mr-[16px] md:my-[20px] md:mx-[20px] cursor-pointer`}
            imgSrc={`/images/ricebowl.jpg`}
            imgAlt={`Ricebowl`}
            title={`Ricebowl`}
            descPrice={`18.000`}
          /> */}
          <ServiceItem
            onClick={() => handleClick('paket')}
            classWrapper={`mr-[16px] md:my-[20px] md:mr-[20px] cursor-pointer`}
            imgSrc={`/images/paket-box-2nd.jpg`}
            imgAlt={`Paket Box`}
            title={`Paket Box`}
            descPrice={`25.000`}
          />
          <ServiceItem
            onClick={() => handleClick('tumpeng')}
            classWrapper={`mr-[16px] md:my-[20px] md:mx-[20px] cursor-pointer`}
            imgSrc={`/images/tumpeng.jpg`}
            imgAlt={`Tumpeng`}
            title={`Tumpeng`}
            descPrice={`37.000`}
          />
          {/* <ServiceItem
            onClick={() => handleClick('bento')}
            classWrapper={`mr-[16px] md:my-[20px] md:mx-[20px] cursor-pointer`}
            imgSrc={`/images/resto.jpg`}
            imgAlt={`Menu Bento`}
            title={`Menu Bento`}
            descPrice={`25.000`}
          /> */}
          <ServiceItem
            onClick={() => handleClick('snack')}
            classWrapper={`mr-[16px] md:my-[20px] md:mx-[20px] cursor-pointer`}
            imgSrc={`/images/snack.jpg`}
            imgAlt={`Snack`}
            title={`Snack`}
            descPrice={`15.000`}
          />
          <ServiceItem
            onClick={() => handleClick('buffet')}
            classWrapper={`md:my-[20px] md:ml-[20px] cursor-pointer`}
            imgSrc={`/images/buffet.jpg`}
            imgAlt={`Menu Buffet`}
            title={`Menu Buffet`}
            descPrice={`50.000`}
          />
        </div>
        {/* RESPONSIVE INFORMATION & TOOLTIP */}
        <div className="flex items-end md:hidden mt-6">
          <p className="text-sm">
            <strong className="text-primary">Minimal Order mulai dari</strong>
          </p>
          <div className="flex text-[0.715rem] ml-2">
            <strong>Menu Catering:</strong>&nbsp;15 Porsi &bull;&nbsp;
            <strong>Menu Restoran:</strong>&nbsp;Rp 1.000.000{' '}
            <Tippy
              content={
                <div className="p-2 text-xs">
                  Bisa digabungkan dengan
                  <br /> menu yang lain selama
                  <br /> satu pengiriman dalam
                  <br /> restoran yang sama
                </div>
              }
              theme="dark"
              placement="bottom"
            >
              <button>
                <AiOutlineInfoCircle className="text-primary w-4 h-4 ml-1" />
              </button>
            </Tippy>
          </div>
        </div>
        {/* END RESPONSIVE INFORMATION & TOOLTIP */}
      </ServiceWrapper>
      {/* DESKTOP VIEW INFORMATION & TOOLTIP */}
      <div className="w-full">
        <div className="font-normal hidden md:flex justify-center text-[0.715rem] items-baseline">
          <strong className="text-primary text-sm">
            Minimal Order mulai dari
          </strong>
          &nbsp; Menu Catering: 15 Porsi &nbsp; &bull; &nbsp; Menu Restoran: Rp
          1.000.000{' '}
          <Tippy
            content={
              <div className="p-2 text-xs">
                Bisa digabungkan dengan
                <br /> menu yang lain selama
                <br /> satu pengiriman dalam
                <br /> restoran yang sama
              </div>
            }
            theme="dark"
            placement="bottom"
          >
            <button>
              <AiOutlineInfoCircle className="text-primary w-4 h-4 ml-1" />
            </button>
          </Tippy>
        </div>
        <div className="text-center mt-9">
          <button
            className="px-7 py-2 rounded-full bg-primary border-2 border-primary text-white w-full md:w-auto hover:bg-red-800 hover:border-red-800 text-center font-semibold"
            onClick={() => redirectToExploreIfAddressFilled()}
          >
            Pesan Makan Sekarang!
          </button>
        </div>
      </div>
      {/* END DESKTOP VIEW INFORMATION & TOOLTIP */}
      {/* MODAL SET LOCATION */}
      <ModalMaps
        showModal={showModal}
        setShowModal={setShowModal}
        setLatLong={setLatLong}
        setPlace={setPlace}
        setSearchValue={searchValue}
        place={place}
        setURL={setURL}
        setRegion={setRegion}
        isPartnerExist={isPartnerExist}
        setIsPartnerExist={setIsPartnerExist}
        handleSubmitForm={handleSubmitForm}
        isLoadingSave={saveLocationMutation.isLoading}
        hideModal={() => {
          setMenuKeyword(null)
          setMenuPrice(null)
        }}
      />
    </SectionLayout>
  )
}

export default OurServices
