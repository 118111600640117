// import { BsWhatsapp } from 'react-icons/bs'
import FloatingWhatsaapLogo from 'images/floating_whatsapp.svg'
import React from 'react'

function WhatsappButton({ cart }) {
  return (
    <a
      className={`fixed right-5 md:right-10 ${
        cart ? 'lg:bottom-20 bottom-16' : 'bottom-7'
      }`}
      href="https://wa.me/6281285949895?text=Saya%20ingin%20pesan%20Catering%20acara%20di%20kendiXpress"
      target="_blank"
      rel="noreferrer"
    >
      <img src={FloatingWhatsaapLogo} className="w-[38px] md:w-[60px]" />
    </a>
  )
}

export default WhatsappButton
