import Accordion from 'components/accordion/Index'
import InfoLayout from 'components/layouts/InfoLayout'
import React from 'react'
import { useState } from 'react'

function Faq() {
  const [selectedItem, setSelectedItem] = useState('')

  const onChangeItem = (value) => {
    setSelectedItem((prevState) => {
      if (prevState == value) {
        return ''
      } else {
        return value
      }
    })
  }

  return (
    <InfoLayout>
      <div className="py-10 md:py-[100px]">
        {/* ACCORDION ITEMS */}
        <Accordion
          number={`1`}
          value={`one`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Bagaimana cara melakukan pemesanan?`}
          description={`Pemesanan dapat dilakukan melalui website kendixpress.com atau WhatsApp CS kami di 0812 8594 9895`}
        />
        <Accordion
          number={`2`}
          value={`two`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah ada minimal pemesanan?`}
          description={`Untuk menu Catering minimal pemesanan 15 pax dan untuk menu resto minimal pemesanan 1 juta`}
        />
        <Accordion
          number={`3`}
          value={`three`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah harga sudah termasuk dengan biaya pengiriman?`}
          description={`Ya, harga sudah termasuk dengan biaya pengiriman`}
        />
        <Accordion
          number={`4`}
          value={`four`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Kapan waktu maksimal untuk melakukan pemesanan?`}
          description={`Pemesanan maksimal H-1 jam 14.00 WIB`}
        />
        <Accordion
          number={`5`}
          value={`five`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Untuk lokasi dapurnya dimana saja?`}
          description={`Saat ini kami bisa melayani untuk wilayah DKI Jakarta, Bekasi, Tangerang Selatan dan Depok`}
        />
        <Accordion
          number={`6`}
          value={`six`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah bisa melakukan pemesanan untuk 2 kali pengiriman dalan sehari?`}
          description={`Ya, waktu pengiriman bisa fleksibel sesuai request Customer`}
        />
        <Accordion
          number={`7`}
          value={`seven`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah bisa order untuk weekend dan tanggal merah?`}
          description={`Ya, order bisa dilakukan setiap hari`}
        />
        <Accordion
          number={`8`}
          value={`eight`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Bagaimana cara melakukan pembayaran?`}
          description={`Pembayaran dapat dilakukan dengan cara transfer`}
        />
        <Accordion
          number={`9`}
          value={`nine`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah bisa melakukan pembayaran secara COD?`}
          description={`Tidak bisa, pembayaran hanya bisa dilakukan maksimal transfer H-1 sebelum pesanan dikirim`}
        />
        <Accordion
          number={`10`}
          value={`ten`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah bisa melakukan pembayaran dengan TOP, jika bisa berapa lama maksimal waktu pembayaran?`}
          description={`Bisa, untuk Customer B2B bisa melakukan pembayaran secara TOP dengan maksimal pembayaran 14 hari`}
        />
        <Accordion
          number={`11`}
          value={`eleven`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah item menu bisa diganti sesuai request?`}
          description={`Ya, untuk menu bisa di request sesuai dengan selera atau pilihan Customer. Untuk Menu Andalan Catering minimal pemesanan 15 pax, untuk Menu Resto minimal pemesanan 1 juta sedangkan untuk Menu Harian Catering minimal pemesanan 1 porsi`}
        />
        <Accordion
          number={`12`}
          value={`twelve`}
          checked={selectedItem}
          onChange={onChangeItem}
          title={`Apakah bisa melakukan pemesanan langsung untuk 1 minggu?`}
          description={`Ya, pemesanan bisa harian maupun mingguan`}
        />
        {/* END ACCORDION ITEMS */}
      </div>
    </InfoLayout>
  )
}

export default Faq
