import $axios from 'utils/axios'

export const order = async (data) => {
  const result = await $axios.post('/order/v2/customer', data)
  return result.data
}

export const checkUniqueCode = async () => {
  const result = await $axios('/order/v2/customer/unique-code')
  return result.data.result
}

export const fetchListOrder = async (page = 0) => {
  const result = await $axios(`/order/v2/customer/list?page=${page}`)
  return result.data
}

export const fetchDetailOrder = async (id) => {
  const result = await $axios(`/order/v2/customer?id=${id}`)
  return result.data
}

export const checkPaymentStatus = async (order_id) => {
  const result = await $axios(
    `/order/v2/customer/payment/check?order_id=${order_id}`
  )
  return result.data
}
