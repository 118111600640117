import Tippy from '@tippyjs/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

function Alert() {
  return (
    <div className="w-full py-[12px] px-[20px] md:py-4 md:px-4 bg-alert">
      <div className="container px-0 md:px-4">
        <div className="font-normal hidden md:flex text-[0.715rem] items-baseline">
          <strong className="text-primary text-sm">
            Minimal Order mulai dari
          </strong>
          &nbsp; Menu Catering: 15 Porsi &nbsp; &bull; &nbsp; Menu Restoran: Rp
          1.000.000{' '}
          <Tippy
            content={
              <div className="p-2 text-xs">
                Bisa digabungkan dengan
                <br /> menu yang lain selama
                <br /> satu pengiriman dalam
                <br /> restoran yang sama
              </div>
            }
            theme="dark"
          >
            <button>
              <AiOutlineInfoCircle className="text-primary w-4 h-4 ml-1" />
            </button>
          </Tippy>
        </div>
        <div className="block md:hidden">
          <p className="text-sm">
            <strong className="text-primary">Minimal Order mulai dari</strong>
          </p>
          <div className="flex text-[0.650rem]">
            <strong>Menu Catering:</strong>&nbsp;15 Porsi &bull;&nbsp;
            <strong>Menu Restoran:</strong>&nbsp;Rp 1.000.000{' '}
            <Tippy
              content={
                <div className="p-2 text-xs">
                  Bisa digabungkan dengan
                  <br /> menu yang lain selama
                  <br /> satu pengiriman dalam
                  <br /> restoran yang sama
                </div>
              }
              theme="dark"
            >
              <button>
                <AiOutlineInfoCircle className="text-primary w-4 h-4 ml-1" />
              </button>
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alert
