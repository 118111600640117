import Modal from 'components/modal/Index'
import React from 'react'

const ModalPopup = ({
  show,
  setShow,
  image,
  title,
  desc,
  contact,
  buttonTitle,
  onClick,
}) => {
  return (
    <Modal
      showModal={show}
      isModalResponsiveFullPage={false}
      isModalResponsiveRounded={true}
      modalClass={`!w-[380px] z-50`}
      modalBodyClass={`p-[32px] pt-0 sm:!p-[40px] sm:!pt-[40px]`}
      hideTitleSection
    >
      <div className="flex items-center justify-center flex-col">
        <img src={image} alt={title} className="w-[180px] " />
        <h2 className="text-lg text-center font-semibold">{title}</h2>
        <p className="text-sm text-center mt-1 mb-5">{desc}</p>
        {contact && (
          <a
            href="https://wa.me/6281285949895?text=Saya%20sudah%20bayar,%20namun%20di%20list%20pesanan%20masih%20belum%20terupdate"
            target="_blank"
            rel="noreferrer"
            className="text-sm mb-4 font-semibold text-center hover:opacity-80 duration-200 hover:text-white hover:bg-primary text-primary border-primary border rounded-full py-3 w-full"
          >
            Hubungi Kami
          </a>
        )}
        <button
          onClick={onClick ? onClick : () => setShow(false)}
          className="text-sm font-semibold w-full hover:opacity-90 duration-200 rounded-full py-3 bg-primary text-white"
        >
          {buttonTitle}
        </button>
      </div>
    </Modal>
  )
}

export default ModalPopup
