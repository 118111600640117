import React from 'react'

function FooterHelp({ errorMessage = '' }) {
  return (
    <div className={`${errorMessage ? 'mt-4' : 'mt-7'} text-center`}>
      {errorMessage && (
        <div className="text-[10px] leading-3 mb-1 text-primary">
          {errorMessage}
        </div>
      )}
      <div className="text-[10px] leading-3 text-black">
        Butuh Bantuan?{' '}
        <a
          href="https://wa.me/6281285949895?text=Saya%20mengalami%20kesulitan%20ketika%20mendaftar%20di%20website"
          target={'_blank'}
          rel="noreferrer"
          className="text-primary"
        >
          Klik disini
        </a>
      </div>
    </div>
  )
}

export default FooterHelp
