import Loading from 'components/layouts/fragments/Loading'
import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { MONTH_NAMES } from 'utils/date'
import { currency } from 'utils/formatNumber'

import Tooltip from './Tooltip'

export const SuccessCopied = () => {
  return (
    <div className="bg-black rounded-md font-normal absolute -top-8 -left-4 p-1 px-2 whitespace-nowrap text-[10px] text-white">
      Berhasil disalin
    </div>
  )
}

const DataOrder = ({ visible, setVisible, data, date, index }) => {
  const width = innerWidth
  const navigate = useNavigate()
  const [copied, setCopied] = useState(false)

  // copy clipboard
  const copyToClipboard = async (text) => {
    try {
      copy(text)
      setCopied(true)
    } catch (e) {
      alert(e)
    }
  }

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])

  if (date.length == 0) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <div className="p-4 py-6 md:px-12 flex flex-col rounded-b-2xl gap-4 bg-white">
      <div className="grid lg:grid-cols-2 gap-4">
        <div className="col-span-1 flex flex-col gap-4">
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">
              {new Date(data.transaction_date * 1000).getDate()}{' '}
              {MONTH_NAMES[new Date(data.transaction_date * 1000).getMonth()]}{' '}
              {new Date(data.transaction_date * 1000).getFullYear()}
            </p>
            <p className="text-sm font-semibold">
              Order ID: {data.order_number}
            </p>
            {(data.invoice_status == 'U' || data.invoice_status == 'P') && (
              <a
                href={data.invoice_url}
                target="_blank"
                rel="noreferrer"
                className="text-primary items-center flex gap-1 text-[10px]"
              >
                <HiDownload /> Unduh Invoice
              </a>
            )}
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Nama Mitra</p>
            <p className="text-sm font-semibold">{data.partner_name}</p>
          </div>
        </div>
        <div
          className={`col-span-1 flex flex-col gap-4 ${
            data.invoice_status == 'U' ? 'md:gap-7' : 'md:gap-4'
          }`}
        >
          <div className="flex flex-col w-fit">
            <p className="text-gray-400 text-xs">Total Pembayaran</p>
            <div className="flex gap-2">
              {index == 0 ? (
                <Tooltip
                  visible={visible}
                  setVisible={setVisible}
                  placement={width > 600 ? 'right' : 'top-end'}
                  desc={
                    <p>
                      <span className="hidden md:block">
                        Pastikan transfer sesuai dengan jumlah <br /> angka yang
                        tertera pada total bayar
                      </span>
                      <span className="md:hidden block">
                        Pastikan transfer sesuai <br /> dengan jumlah angka yang{' '}
                        <br />
                        tertera pada total bayar
                      </span>
                    </p>
                  }
                >
                  <p className="text-sm w-fit flex font-semibold">
                    {currency(data.grand_total)}
                  </p>
                </Tooltip>
              ) : (
                <p className="text-sm w-fit flex font-semibold">
                  {currency(data.grand_total)}
                </p>
              )}
              <input
                type="text"
                value={data.grand_total}
                className="hidden"
                readOnly
              />
              <button
                onClick={() => copyToClipboard(data.grand_total)}
                className="text-primary relative font-semibold text-sm"
              >
                {copied ? 'Disalin' : 'Salin'}
                {copied && <SuccessCopied />}
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Tanggal Pengiriman</p>
            <div className="text-[13px] flex-wrap md:text-sm flex font-semibold">
              {date.map((data, index) => (
                <div key={index} className="flex mr-1">
                  {data.map((item, key) => (
                    <p key={key} className="flex first:ml-0">
                      {new Date(item).getDate()}
                      {data.length - 1 == key && (
                        <React.Fragment>
                          <span className="ml-1">
                            <span>
                              {MONTH_NAMES[new Date(item).getMonth()]}{' '}
                              {new Date(item).getFullYear()}
                            </span>
                          </span>
                          {date.length - 1 !== index && (
                            <span className="text-xl ml-1 -mt-1.5 md:-mt-1">
                              &#8226;
                            </span>
                          )}
                        </React.Fragment>
                      )}
                      {data.length - 1 !== key && <span>,</span>}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <p className="text-gray-400 text-xs">Alamat Pengiriman</p>
        <p className="text-sm font-semibold">{data.delivery_address}</p>
        <p className="text-sm font-semibold">{data.detail_location}</p>
      </div>
      <button
        onClick={() => navigate(`/user/order/${data.secure_id}`)}
        className="bg-primary md:max-w-[220px] text-white font-semibold text-sm py-3 px-9 hover:opacity-80 transition-all duration-500 rounded-full"
      >
        Lihat Detail Pesanan
      </button>
    </div>
  )
}

export default DataOrder
