import LoadingButton from 'components/button/LoadingButton'
import Loading from 'components/layouts/fragments/Loading'
import PaymentLayout from 'components/layouts/PaymentLayout'
import { useOrder } from 'modules/custom/useOrder'
import React, { useEffect } from 'react'
import { MONTH_NAMES } from 'utils/date'

import InfoCard from './components/InfoCard'
import OrderDetail from './components/OrderDetail'
import Recipient from './components/Recipient'

const PaymentConfirm = () => {
  const {
    loading,
    setLoading,
    userProfile,
    dateLate,
    alertAvail,
    lateOrder,
    handleSubmit,
  } = useOrder()

  const beforeDay = new Date(dateLate).setDate(new Date(dateLate).getDate() - 1)

  useEffect(() => {
    sessionStorage.removeItem('payment-confirmation')
  }, [])

  if (userProfile.isLoading)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
    )

  return (
    <React.Fragment>
      <PaymentLayout
        header="Konfirmasi Pesanan"
        headForm="Konfirmasi Pesanan"
        descriptionForm="Harap pastikan detail pesanan dan pengirimanmu sudah sesuai"
      >
        <div className="container grid grid-cols-6 gap-11 md:py-6">
          <div className="col-span-6 flex flex-col border-b border-t md:border-t-0 md:border-b-0 gap-6 lg:col-span-4">
            <OrderDetail />
            <div className="border-b md:hidden"></div>
            <Recipient />
          </div>

          <div className="col-span-6 lg:col-span-2">
            <InfoCard />
          </div>
        </div>
        <div className="container grid grid-cols-6 md:border-b md:py-4">
          <div className="col-span-6 flex flex-cl gap-6 lg:col-span-4">
            <div className="flex md:h-28 mt-5 flex-col w-full gap-2">
              <LoadingButton
                isLoading={loading}
                onClick={() => {
                  setLoading(true)
                  handleSubmit()
                }}
                className="bg-primary !w-full text-white py-3 font-semibold text-sm rounded-full hover:opacity-80 active:opacity-90"
              >
                Pembayaran
              </LoadingButton>
              {lateOrder && (
                <p className="text-primary text-center text-[10px]">
                  Pemesanan untuk tanggal{' '}
                  {`${new Date(dateLate).getDate()} ${
                    MONTH_NAMES[new Date(dateLate).getMonth()]
                  } ${new Date(dateLate).getFullYear()}`}
                  , maksimal bisa dilakukan tanggal{' '}
                  {`${new Date(beforeDay).getDate()} ${
                    MONTH_NAMES[new Date(beforeDay).getMonth()]
                  } ${new Date(beforeDay).getFullYear()}`}{' '}
                  pukul 14:00.
                  <br /> Silahkan ubah pesanannmu dan pilih tanggal lain untuk
                  bisa diteruskan
                </p>
              )}
              {alertAvail && (
                <p className="text-primary text-center text-[10px]">
                  Beberapa menu yang kamu pilih sudah tidak tersedia, <br />
                  jika kamu masih menginginkan menu tersebut harap{' '}
                  <a href="https://wa.me/6281285949895">
                    <strong> hubungi Admin Kami</strong>
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </PaymentLayout>
    </React.Fragment>
  )
}

export default PaymentConfirm
