import emptyMenu from 'images/menu/empty-menu.svg'
import React from 'react'

import EmptyWrapper from './fragments/EmptyWrapper'

const EmptyMitra = () => {
  const location = 'Jakarta'
  return (
    <EmptyWrapper
      image={emptyMenu}
      link={`https://wa.me/6281285949895?text=Saya%20ingin%20pesan%20menu%20Catering/Restoran%20untuk%20Wilayah%20${location}`}
      text={'Hubungi Admin'}
    >
      <h3 className="text-base capitalize font-semibold">
        Kami Mohon Maaf, <br />
        Lokasi ini belum dapat dijangkau mitra kami
      </h3>
      <p className="text-sm">
        Kamu menginginkan menu untuk wilayah ini? <br />
        Silahkan hubungi admin kendiXpress
      </p>
    </EmptyWrapper>
  )
}

export default EmptyMitra
