import LockIcon from 'images/icons/lock-red.svg'
import WhatsappIcon from 'images/icons/whatsapp-red.svg'
import React from 'react'

function OtpAlternativesContent({ setStep }) {
  return (
    <div className="flex flex-col w-full">
      <h4 className="text-lg text-black font-semibold mb-1">
        Metode Verifikasi Lainnya
      </h4>
      <span className="text-sm text-black leading-6">
        Hubungi admin terlebih dahulu untuk
        <br />
        mendapatkan kode verifikasi
      </span>

      <div className="mt-4">
        <div className="flex py-5 px-4 my-5 items-center border border-[#CECECE] rounded-lg bg-white cursor-pointer">
          <a
            href="https://wa.me/6281285949895?text=Saya%20tidak%20mendapatkan%20kode%20verifikasi%20ketika%20masuk%20atau%20lupa%20kata%20sandi"
            target={'_blank'}
            rel="noreferrer"
            className="contents"
          >
            <img src={WhatsappIcon} className="w-7 h-7" />
            <div className="ml-7 text-sm text-black">Hubungi Admin</div>
          </a>
        </div>
        <div
          className="flex py-5 px-4 my-5 items-center border border-[#CECECE] rounded-lg bg-white cursor-pointer"
          onClick={() => setStep('input-alternative-otp')}
        >
          <img src={LockIcon} className="w-7 h-7" />
          <div className="ml-7 text-sm text-black">
            Masukkan Kode Verifikasi
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtpAlternativesContent
