import Loading from 'components/layouts/fragments/Loading'
import UserLayout from 'components/layouts/UserLayout'
import successIcon from 'images/success-icon.svg'
import { fetchListOrder } from 'modules/Order/api'
import { useFetchUserProfile } from 'modules/User/hook'
import React, { useEffect, useState } from 'react'

import Card from './components/Card'
import EmptyOrder from './components/EmptyOrder'
import ListOrder from './components/ListOrder'
import ModalPopup from './components/ModalPopup'
import Sidebar from './components/Sidebar'

const OrderList = () => {
  const [visible, setVisible] = useState(
    JSON.parse(localStorage.getItem('tool')) || false
  )
  const [modalTrigger, setModalTrigger] = useState(
    JSON.parse(sessionStorage.getItem('order')) || false
  )
  const dataHook = useFetchUserProfile()
  const [dataList, setDataList] = useState(
    JSON.parse(sessionStorage.getItem('dataList')) || ''
  )
  const [totalPage, setTotalPage] = useState(
    JSON.parse(sessionStorage.getItem('totalPage')) || ''
  )
  const check = JSON.parse(sessionStorage.getItem('detail-order'))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!modalTrigger) sessionStorage.removeItem('order')
  }, [modalTrigger])

  useEffect(() => {
    if (dataList.length == 0 || !check) {
      sessionStorage.removeItem('page')
      setLoading(true)
      const fetchData = async () => {
        const data = await fetchListOrder()
        if (data?.result?.data.length > 0) {
          setDataList([data?.result?.data])
          setTotalPage(data?.pagination?.total_page)
          sessionStorage.setItem(
            'dataList',
            JSON.stringify([data?.result?.data])
          )
          sessionStorage.setItem(
            'totalPage',
            JSON.stringify(data?.pagination?.total_page)
          )
        }
        setLoading(false)
      }
      fetchData()
    }
    if (check) sessionStorage.removeItem('detail-order')
  }, [])

  return (
    <UserLayout>
      <div className="grid md:grid-cols-10 gap-11 py-4 bg- md:py-10">
        {/* DESKTOP */}
        <div className="col-span-3 hidden md:block">
          {/* SIDEBAR DESKTOP */}
          <Sidebar
            name={dataHook?.data && dataHook?.data?.name}
            isLoading={dataHook.isFetching}
          />
          {/* END SIDEBAR DESKTOP */}
        </div>

        <div className="col-span-7 w-full flex flex-col gap-4">
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              {dataList.length == 0 && <EmptyOrder />}

              {dataList.length > 0 && (
                <>
                  <Card className={`py-10 px-12 hidden md:block`}>
                    {/* TITLE */}
                    <div className="">
                      <h1 className="text-[26px] leading-9 text-black font-semibold">
                        Daftar Pesanan
                      </h1>
                      <div className="text-sm text-black mt-1">
                        Semua riwayat pesananmu bisa dilihat disini
                      </div>
                    </div>
                  </Card>

                  <ListOrder
                    data={dataList}
                    totalPage={totalPage}
                    visible={visible}
                    setVisible={setVisible}
                    setDataList={setDataList}
                  />

                  <Card className={`p-4 md:py-10 md:px-12`}>
                    <div className="flex flex-wrap gap-3 justify-between">
                      <p className="text-xs text-black">
                        Ingin melakukan perubahan pada pesanan atau invoice
                      </p>
                      <a
                        href="https://wa.me/6281285949895?text=Saya%20ingin%20melakukan%20perubahan%20terhadap%20pesanan%20saya%20pada%20tanggal"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white bg-primary rounded-full font-semibold text-[10px] py-1.5 px-4"
                      >
                        Hubungi Kami
                      </a>
                    </div>
                  </Card>
                </>
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {/* MODAL SUCCESS */}
      <ModalPopup
        show={modalTrigger}
        setShow={setModalTrigger}
        image={successIcon}
        title="Pesanan Berhasil Dibuat"
        desc={
          <>
            Silahkan cek pesananmu
            <br />
            sebelum melakukan pembayaran
          </>
        }
        buttonTitle="Lanjut"
      />
    </UserLayout>
  )
}

export default OrderList
